.main_contact {
  background: #ffffff;
  border-radius: 8px;
  padding: 30px;
  margin: auto;
  /* margin: 0 auto; */
  /* width: 50%; */
  /* border: 3px solid green; */
  padding: 10px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 20%);
  /* width: 100px; */
  /* background-color: red; */
  position: absolute;
  height: 327px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.input_div{
  margin-bottom: 20px;
}

.share_heading {
  font-size: 28px;
  color: #000000;
  font-family: Inter, Bold;
  margin-bottom: 24px;
  text-align: center;
}

.input_main_div {
  display: grid;
}

.input_box {
  border-radius: 50px;
  border: 2px solid #ccced3c2;
  font-size: 16px;
  font-weight: 600;
  color: #000000;
  padding: 12px 8px 12px 15px;
  font-size: inter, Semi Bold;
  width: 100%;
}

input::placeholder {
  font-weight: bold;
  opacity: 0.8;
  color: #ccced3c2;
}

.share_img {
  display: flex;
  justify-content: center;
  gap: 100px;
}

.ant-modal-footer {
  display: none;
}

.ant-modal-title {
  display: none;
}

.ant-modal-close-x {
  display: none;
}
/* 
 .ant-modal {
    width: 330px;
 } */

.ant-modal-close-x {
  display: none !important;
}

.contact_modal {
  max-width: 380px;
  margin: auto;
}

.ant-modal-mask {
  max-width: 400px;
  background-color: #fff !important;

  background: #fff;
  width: 400px;
  margin: auto;
  position: absolute !important;
  top: 40px !important;
  height: max-content !important;
  z-index: 0 !important;
}
.ant-modal-wrap {
  background: #fff;
  top: 130px !important;
  max-width: 450px;
  margin: auto;
}
@media screen and (max-width: 530px) {
  .ant-modal-wrap {
    top: 0px !important;
    overflow-y: scroll !important;
  }
  .header:has(.ant-modal-content) {
    display: none;
  }
}
.error{
  font-size: 14px;
  color: red;
  margin-left: 4%;
}
.share_cancel_img,.share_save_img{
  width: 60px;
  height: 60px;
}

@media screen and (max-width:530px) {
  .body-class .header,
  .body-class .lowerSection {
    display: none;
  }
}

.body-class .button{
  display: none;
}