.add_audio_stop {
  display: flex;
  align-items: center;
  gap: 28px;
  margin-left: 18.5%;
}
.stop_image{
  width: 64px;
  height: 64px;
}

@media screen and (max-width:400px) {
  .add_audio_stop{
    margin-left:17%;
    gap: 28px;
  }
  .background_img{
    width: 70px;
  }
}
@media screen and (max-width:370px) {
  .add_audio_stop{
    margin-left: 14%;
  }
}