@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap');

* {
  box-sizing: border-box;
  margin: 0;


}

body {
  overflow-x: hidden;
  font-size: 62.5%;
  font-family: 'Inter', sans-serif;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* /  -webkit-box-shadow: 0 4px 6px -6px rgb(110, 110, 110); */
  /* -moz-box-shadow: 0 4px 6px -6px rgb(110, 110, 110); */
  box-shadow: 0 1px 21px 0 rgb(32 33 36 / 28%);
  /* box-shadow: 0 15px 10px -19px #111/; */
  /* border-bottom: 1px solid gray; */
  padding: 1rem 1rem;
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 9999999;
}

.menuBar_li {
  margin-left: 20px;
}

.menuBar {
  display: flex;
  justify-content: space-between;
  list-style: none;
  /* gap: 2rem; */
  align-items: center;
}

.menu_text {
  text-decoration: none;
  color: black;
  font-weight: 700;
  font-size: 1.2rem;
  /* max-width: 1rem;  */
}

.DownloadBTN {
  cursor: pointer;
  /* padding: 1.3rem 3rem; */
  /* border-radius: 1rem; */
  border: none;
  font-weight: 700;
  font-size: 1.2rem;
  color: #fff;
  background: transparent;
  /* background: rgb(57, 161, 157);
    background: linear-gradient(
      90deg,
      rgba(57, 161, 157, 1) 8%,
      rgba(99, 162, 128, 1) 33%,
      rgba(154, 163, 89, 1) 58%,
      rgba(214, 165, 48, 1) 82%
    ); */
  /* background-image: url(../../assets/Downloadlogo\ App.png) ; */

  display: flex;
  align-items: center;
  /* margin-right: 50px; */
}