.other_main{
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 15px 15px 50px;
    margin-top: 20px;
}
.input_div {
  margin: 2%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.input_div input {
  padding: 10px;
  border-radius: 8px;
  border: 1px solid lightgray;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 6px 12px 0px;
  color: grey;
  font-size: 16px;
}
.input_div input::placeholder {
  color: rgba(128, 128, 128, 0.397);
}
.input_div input:active {
    border: 1px solid lightgrey;
}
.input_div input:focus {
    border: 1px solid lightgrey;
}

h1{
  text-align: center;
  margin: 5%;
}
/* .generate_btn {

} */

.container{
  padding: 10px;
  border-radius: 8px;
  border: 1px solid lightgray;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 6px 12px 0px;
  color: grey;
  font-size: 16px;
}

.generate_btn{
  background-color: #008080;
  color: #FFFFFF;
  border: 1px solid white;
  font-weight: bold;
  padding: 10px;
  border-radius: 10px;
  margin-top: 15px;
  /* text-align: center; */
}
.generate_btn_div{
  /* text-align: center; */
  display: flex;
  justify-content: center;
  /* text-align: cent er; */
  /* margin-left: 50%;
  margin-top: 10%; */
}

.error{
  font-size: 14px;
  color: red;
  text-align: left;
  margin-left: 4%;
}

.image-field{
  margin: 0px;
  padding: 0px;
  word-break: break-all;
}