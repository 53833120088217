.create_div {
  box-shadow: 0px 0px 12px #00000033;
  border-radius: 8px;
  margin-top: 20px;
  /* height: 423px; */
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  /* padding: 20px 0px; */
}
.main_create_div {
  /* padding: 40px 30px 30px; */
  padding: 29px 27px 17px;

  text-align: center;
}
.create_div h2 {
  font-size: 28px;
  line-height: 32px;
  color: #000000;
  font-weight: bold;
  margin: 0px 0px 12px;
}
.create_div p {
  font-size: 16px;
  line-height: 20px;
  margin: 0px auto 12px;
  max-width: 280px;
}
.create_div_lower {
  padding: 30px 18px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.margin_class{
  margin-top: 54px;
}

.create_div_lower div {
  width: calc(100% / 3);
}
.create_div_lower .div2 {
  text-align: center;
}
.create_div_lower img {
  width: 50px;
  height: 50px;
}
.main_create_div h6 {
  margin: 0px;
  font-size: 10px;
  line-height: 20px;
  font-weight: normal;
  text-decoration: underline;
}
.create_div_lower .photo1 {
  width: 100px;
}
.create_div_lower .photo2 {
  width: 100px;
  height: auto;
}
.add_detail_div{
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.main_create_div .add_detail_input {
  border-radius: 15px;
  border: 1px solid lightgray;
  padding: 10px 15px;
  font-size: 15px;
}

.add_detail div {
  width: calc(100% / 1);
  /* text-align: center; */
  text-align: -webkit-center;
}
.popup {
  background-color: #28a0a8;
  padding: 50px 0px;
  box-shadow: 0px 0px 12px #00000033;
  border-radius: 8px;
  margin-top: 15px;
}
.share_div {
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px 10px 30px;
  max-width: 180px;
  margin: auto;
}
.share_div div {
  width: calc(100% / 2 - 20px);
  text-align: center;
}
.share_div div img {
  width: 64px;
  height: 64px;
}
.share_div div p{
  font-size: 10px;
  line-height: 18px;
  
}
.share_main_div {
  box-shadow: none;
}
.share_main_div .main_create_div {
  padding: 10px 30px;
}
.download_app_btn {
  background: none;
  border: none;
}
.play_div {
  text-align: center;
}

.error{
  font-size: 14px;
  color: red;
  text-align: left;
  margin-left: 4%;
}




/* Popup container - can be anything you want */
.popup1 {
  position: relative;
  display: inline-block;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* The actual popup */
.popup1 .popuptext {
  visibility: hidden;
  width: 160px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 8px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -80px;
}

/* Popup arrow */
.popup1 .popuptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

/* Toggle this class - hide and show the popup */
.popup1 .show {
  visibility: visible;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}

/* Add animation (fade in the popup) */
@-webkit-keyframes fadeIn {
  from {opacity: 0;} 
  to {opacity: 1;}
}

@keyframes fadeIn {
  from {opacity: 0;}
  to {opacity:1 ;}
}