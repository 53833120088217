.main_popup {
  /* height: 100px;  */
  top: -20px;
  left: -20px;
  margin: auto;

  /* position: absolute;
      top: 0%;
      left: 50%;
      transform: translate(-50%, -0%);
      z-index: 2; */
}
.popup_Image{
  width: 100%;
  height: 150px;
}
.pop_main {

  margin: 3rem auto 0rem;
  max-width: 400px;
}


.body-class {
  max-height: 100vh;
  overflow: hidden;
}