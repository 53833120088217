.add_audio {
  display: flex;
  align-items: center;
  gap: 13px;
  margin-left: 13.5%;
  text-align: center;
}

.stop_image {
  width: 64px;
  height: 64px;
  cursor: pointer;
}

.add_main {
  position: sticky;
  bottom: 0px;
  display: flex;
  padding: 8px 0px;
  margin: auto;
}

.add_audio_content {
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
}

@media screen and (max-width:400px) {
  .add_audio {
    margin-left: 10%;
    gap: 16px;

  }

  .add_mic_div .add_img {
    width: 83px;
  }

}

@media screen and (max-width:350px) {
  .add_audio {
    margin-left: 4%;
  }
}