@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap');

* {
  box-sizing: border-box;
  margin: 0;


}

body {
  overflow-x: hidden;
  font-size: 62.5%;
  font-family: 'Inter', sans-serif;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 15px 10px -15px #111;
  padding: 1rem 1rem;
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 2;
}

.heading {
  font-size: 2.5rem;
  /* margin-bottom: 1rem; */
  font-weight: 700;
  line-height: 36pts;
  padding-left: 10px;

}

.menu {
  display: flex;
  justify-content: space-between;
}

.sub_heading {
  font-weight: 500;
  font-size: 0.9rem;
  padding-left: 10px;
}
 

.wrapper {
  overflow-y: scroll;
  height: 30vh;
}

.wrapper::-webkit-scrollbar {
  display: none;
}

.userSVg {
  margin-top: 1rem;
  width: 100%;
  height: 100%;
  object-fit: scale-down
}

.sub_user {
  border-radius: 50%;
  background-color: #111;
}

.DownloadBTN2 {
  padding: 1rem;
  border-radius: 1rem;
  border: none;
  font-size: 1.2rem;
  font-weight: 700;
  color: #fff;
  background: rgb(57, 161, 157);
  background: linear-gradient(90deg,
      rgba(57, 161, 157, 1) 8%,
      rgba(99, 162, 128, 1) 33%,
      rgba(154, 163, 89, 1) 58%,
      rgba(214, 165, 48, 1) 82%);

}

.content {
  margin: 3rem auto 0rem;
  max-width: 400px;
  /* padding: 2rem; */
  position: unset;
}

.sub_content {
  display: flex;
  /* gap: 0.7rem; */
  margin-left: 7px;
  margin-top: 0.8rem;
}


.subhead {
  margin: 0rem;
  font-size: 1rem;
  display: flex;
  /* margin-left: 10px; */
  /* gap: 7px; */
  font-weight: 700;
}


.sub_description {
  margin: 0;
  font-size: 1.0rem;
  font-weight: 500;
  display: inline-block;


}

.details {
  color: gray;
  font-size: 0.9rem;
  font-weight: 700;
  margin-left: 10px;
  opacity: 50%;
}

.AppButton {
  padding: 1.2rem;
}

.button {
  position: sticky;
  bottom: 0px;
  padding: 1rem 0rem;
  display: flex;
  justify-content: center;
  margin: auto;
}
.button::before{
  background-image: url(../../assets/background.png);
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  opacity: 0.95;
}
.button2 {
  cursor: pointer;
  margin: auto;
}

.sub_description::-webkit-scrollbar {
  display: none;
}

.playerBTN {
  margin-bottom: 0.6rem;
  display: flex;
  align-items: center;
  /* gap: 1rem; */
  justify-content: center;
  margin-top: 1.5rem;
}

.content_btn {
  display: none;
  width: 80%;
}

.wrapper_video {
  position: relative;
}

.mute_btn {
  position: absolute;
  bottom: 0px;
  transform: translate(50%);
  right: 5rem;
}

.right {
  margin-left: 15px;
}
.bio_div{
  width: 100%;
}

/* .playerOne_btn{  
  margin-left: 48px;
  margin-right: 48px;
} */
@media screen and (max-width: 700px) {
  .menuBar{
    display: none !important;
  }

  .header {
    flex-direction: row;
  }

  .content {
    padding: 2rem 0px 0px 0px;
    margin-top: 8px;
  }

  .mute_btn {
    position: absolute;
    bottom: 0px;
    transform: translate(50%);
    right: 3rem;
  }
}

@media screen and (max-width: 780px) {
  .menuBar {
    display: none !important;
  }

  .header {
    flex-direction: row;
  }
}


@media screen and (max-width: 900px) {
  .menu_text {
    font-size: 1.1rem;
  }
}

@media screen and (max-width: 850px) {
  .menu_text {
    font-size: 1rem;
  }

  .DownloadBTN {
    padding: 1rem;
    font-size: 0.7rem;
  }

  .header {
    flex-direction: row;
    padding: 1rem 1rem;
  }

  .heading {
    font-size: 1.5rem;
  }

  .menu_text {
    font-size: 0.9rem;
  }

  .DownloadBTN2 {
    padding: 1rem;
    font-size: 0.7rem;
  }

  .menuBar {
    /* gap: 1rem; */
    margin-left: 10px;
    padding: 0px;
  }
}




@media screen and (max-width: 530px) {
  .menu_text {
    font-size: 0.6rem;
  }
/* .content{
    display: none;
  } */
}

@media screen and (max-width: 480px) {

  .menuBar {
    /* gap: 0.5rem; */
    margin-left: 5px;
    padding: 0px;
  }

  .DownloadBTN {
    padding: 0.5rem;
    font-size: 0.7rem;
  }

  .menu_text {
    font-size: 0.9rem;
  }

  .menuBar {
    display: none !important;
  }

  .header {
    flex-direction: row;
  }

  .content_btn {
    display: block;
  }

  .button {
    /* display: none; */
    /* position: relative; */
    background-color: transparent;
  }
}



@media screen and (max-width: 400px) {
  .menuBar {
    display: none !important;
  }
  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap');

  * {
    box-sizing: border-box;
    margin: 0;
  
  
  }
  
  body {
    overflow-x: hidden;
    font-size: 62.5%;
    font-family: 'Inter', sans-serif;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 15px 10px -15px #111;
    padding: 1rem 1rem;
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 2;
  }
  
  .heading {
    font-size: 2.5rem;
    /* margin-bottom: 1rem; */
    font-weight: 700;
    line-height: 36pts;
  
  }
  
  .menu {
    display: flex;
    justify-content: space-between;
  }
  
  .sub_heading {
    font-weight: 500;
    font-size: 0.9rem;
  }
  
  .wrapper {
    overflow-y: scroll;
    height: 30vh;
  }
  
  .wrapper::-webkit-scrollbar {
    display: none;
  }
  
  .userSVg {
    margin-top: 1rem;
    width: 100%;
    height: 100%;
    object-fit: scale-down
  }
  
  .sub_user {
    border-radius: 50%;
    background-color: #111;
  }
  
  .DownloadBTN2 {
    padding: 1rem;
    border-radius: 1rem;
    border: none;
    font-size: 1.2rem;
    font-weight: 700;
    color: #fff;
    background: rgb(57, 161, 157);
    background: linear-gradient(90deg,
        rgba(57, 161, 157, 1) 8%,
        rgba(99, 162, 128, 1) 33%,
        rgba(154, 163, 89, 1) 58%,
        rgba(214, 165, 48, 1) 82%);
  
  }
  
  .content {
    margin: 3rem auto 0rem;
    max-width: 400px;
    /* padding: 2rem; */
    position: unset;
  }
  
  .sub_content {
    display: flex;
    /* gap: 0.7rem; */
    margin-left: 7px;
    margin-top: 0.8rem;
  }
  
  
  .subhead {
    margin: 0rem;
    font-size: 1rem;
    display: flex;
    /* margin-left: 10px; */
    /* gap: 7px; */
    font-weight: 700;
  }
  
  
  .sub_description {
    margin: 0;
    font-size: 1.0rem;
    font-weight: 500;
    display: inline-block;
  
  
  }
  
  .details {
    color: gray;
    font-size: 0.9rem;
    font-weight: 700;
    margin-left: 10px;
    opacity: 50%;
  }
  
  .AppButton {
    padding: 1.2rem;
  }
  
  .button {
    position: sticky;
    bottom: 0px;
    padding: 1rem 0rem;
    display: flex;
    justify-content: center;
    margin: auto;
  }
  .button::before{
    background-image: url(../../assets/background.png);
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    opacity: 0.95;
  }
  .button2 {
    cursor: pointer;
    margin: auto;
  }
  
  .sub_description::-webkit-scrollbar {
    display: none;
  }
  
  .playerBTN {
    margin-bottom: 0.6rem;
    display: flex;
    align-items: center;
    /* gap: 1rem; */
    justify-content: center;
    margin-top: 1.5rem;
  }
  
  .content_btn {
    display: none;
    width: 80%;
  }
  
  .wrapper_video {
    position: relative;
  }
  
  .mute_btn {
    position: absolute;
    bottom: 0px;
    transform: translate(50%);
    right: 5rem;
  }
  
  .right {
    margin-left: 15px;
  }
  .bio_div{
    width: 100%;
  }
  
  /* .playerOne_btn{  
    margin-left: 48px;
    margin-right: 48px;
  } */
  @media screen and (max-width: 700px) {
    .menuBar {
      display: none !important;
    }
    .DownloadBTN{
      display:  block !important;
    }
  
    .header {
      flex-direction: row;
    }
  
    .content {
      padding: 2rem 0px 0px 0px;
      margin-top: 8px;
    }
  
    .mute_btn {
      position: absolute;
      bottom: 0px;
      transform: translate(50%);
      right: 3rem;
    }
  }
  
  @media screen and (max-width: 780px) {
    .menuBar {
      display: none !important;
    }
  
    .header {
      flex-direction: row;
    }
  }
  
  
  @media screen and (max-width: 900px) {
    .menu_text {
      font-size: 1.1rem;
    }
  }
  
  @media screen and (max-width: 850px) {
    .menu_text {
      font-size: 1rem;
    }
  
    .DownloadBTN {
      padding: 1rem;
      font-size: 0.7rem;
    }
  
    .header {
      flex-direction: row;
      padding: 1rem 1rem;
    }
  
    .heading {
      font-size: 1.5rem;
    }
  
    .menu_text {
      font-size: 0.9rem;
    }
  
    .DownloadBTN2 {
      padding: 1rem;
      font-size: 0.7rem;
    }
  
    .menuBar {
      /* gap: 1rem; */
      margin-left: 10px;
      padding: 0px;
    }
  }
  
  
  
  
  @media screen and (max-width: 530px) {
    .menu_text {
      font-size: 0.6rem;
    }
  /* .content{
      display: none;
    } */
  }
  
  @media screen and (max-width: 480px) {
  
    .menuBar {
      /* gap: 0.5rem; */
      margin-left: 5px;
      padding: 0px;
    }
  
    .DownloadBTN {
      padding: 0.5rem;
      font-size: 0.7rem;
    }
  
    .menu_text {
      font-size: 0.9rem;
    }
  
    .menu {
      /* display: none; */
    }
  
    .header {
      flex-direction: row;
    }
  
    .content_btn {
      display: block;
    }
  
    .button {
      /* display: none; */
      /* position: relative; */
      background-color: transparent;
    }
  }
  
  
  
  @media screen and (max-width: 400px) {
    .menuBar {
      display: none !important;
    }
  
    .header {
      flex-direction: row;
    }
/*   
    .content {
      padding: 2rem;
    } */
  
    .mute_btn {
      position: absolute;
      bottom: 0px;
      transform: translate(50%);
      right: 1rem;
    }
  
    .subhead {
      margin: 0rem;
      font-size: 1rem;
      display: contents;
      margin-left: 10px;
      /* gap: 7px; */
      margin-left: 15px;
      font-weight: 700;
    }
  
  }
  .header {
    flex-direction: row;
  }

  /* .content {
    padding: 2rem;
  } */

  .mute_btn {
    position: absolute;
    bottom: 0px;
    transform: translate(50%);
    right: 1rem;
  }

  .subhead {
    margin: 0rem;
    font-size: 1rem;
    display: contents;
    margin-left: 10px;
    /* gap: 7px; */
    margin-left: 15px;
    font-weight: 700;
  }

}