
.add_main_stop {
  position: sticky;
  bottom: 0px;
  /* display: flex; */
  padding: 7.7px 0px;
}
.cancel_btn{
  width: 48px;
  height: 48px;
}
.record_svg{
  display: flex;
  align-items: center;
  gap: 35px;
  margin-left: 7%;
}
.play_pause{
  width: 64px;
  height: 64px;
}
.record_div{
  margin-left: 14%;
}
@media screen and (max-width:400px) {
  .record_div{
    margin-left: 13.5%;
  }
  
}
@media screen and (max-width:370px) {
  .record_div{
    margin-left:11%;
  }
}
@media screen and (max-width:350px) {
  .record_div{
    margin-left: 7%;
  }
}