.main_upper ul {
  display: flex;
  gap: 30px;
  justify-content: center;
  margin-right: 32px;
}
.main_upper ul li img{
  width: 30px;
  height: 30px;
}
ul {
  list-style-type: none;
}
.main_upper ul li p{
  margin: 5px 0px 0px;
  font-size: 10px;
  line-height: 18px;
  font-weight: normal;
  text-align: center;
}
.main_upper ul li .color {
  padding: 8px;
  background-color: #fff;
  border-radius: 50%;
  text-align: center;
  border: 2px solid #28a0a8;
}
.main_upper ul li {
  position: relative;
}
.main_upper ul li:not(:last-child)::after {
  content: "";
  width: 45px;
  position: absolute;
  height: 2px;
  right: -40px;
  top: 23px;
  background-color: lightgray;
  z-index: -1;
}
.main_lower,
.main_upper {
  padding: 0px 25px;
}
.create_body .main_upper ul li:first-child .color {
  background-color: #28a0a8;
}
.record_body .main_upper ul li:nth-child(-n + 2) .color {
  background-color: #28a0a8;
}
.add_detail_body .main_upper ul li:nth-child(-n + 3) .color {
  background-color: #28a0a8;
}
.share_body .main_upper ul li:nth-child(-n + 4) .color {
  background-color: #28a0a8;
}
.record_body .main_upper ul li:nth-child(-n + 2) .color img {
  filter: invert(100%) saturate(107%) brightness(269%) contrast(152%);
}
.add_detail_body .main_upper ul li:nth-child(-n + 3) .color img {
  filter: invert(100%) saturate(107%) brightness(269%) contrast(152%);
}
.share_body .main_upper ul li:nth-child(-n + 4) .color img {
  filter: invert(100%) saturate(107%) brightness(269%) contrast(152%);
}
.record_body .main_upper ul li:nth-child(-n + 1) .color img,
.add_detail_body .main_upper ul li:nth-child(-n + 1) .color img,
.share_body .main_upper ul li:nth-child(-n + 1) .color img {
  filter: none !important;
}
.flex{
  display: flex;
  flex-direction: row;
}